<template>
  <div class="modal__wrapper">
	<div class="modal__close">
	  <div class="btn" @click="close">
		<Close class="img"/>
	  </div>
	</div>
	<div class="modal__header">
	  <div class="ttl">Import Lead Contacts</div>
	</div>
	<div class="modal__body">
		<div class="row row_files" style="margin-bottom: 16px">
	  		<div class="box box_addFiles">
				<div v-if="getUploadedFiles('file').length" class="content content_many">
				  <draggable v-model="upload.file" tag="div" class="draggable">
				  <div v-for="(file, key) in getUploadedFiles('file')" :key="key" class="file">
					<BlueFile class="img" />
					<div class="desc">{{ file.name }}</div>
				  </div>
				  </draggable>
				</div>
				<div v-else class="content">
				  <div class="choose">
					<div class="btn" @click="$refs.file[0].click()">Import File</div>
				  </div>
				</div>
				  <input
					v-for="(i, key) in upload.file"
					:key="key"
					type="file"
					:name="`file_${key}`"
					class="hide"
					ref="file"
					@change="addInput($event, 'file', key)"
				  >
			  </div>
		</div>
	  <div class="row">
		  <div class="label">First row</div>
		  <div class="data">
			<input type="number" name="row_start" class="inpt" v-model="form.row_start" placeholder="First row">
		  </div>
		</div>
	  <div class="row">
		  <div class="label">Force import</div>
		  <div class="data c-tmblr">
			<Tmblr :active="!!form.force" :class="'c-tmblr'" :text="'Force import'" @changeState="changeState('force')" />
		  </div>
		</div>
	</div>
	<div class="modal__footer">
	  <div class="btn" @click="save">Import</div>
	</div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
	Close,
	Tmblr,
	BlueDownload,
	BlueFile,
	draggable,
  },
  props: {
	options: {
	  type: Object,
	  default: null
	}
  },
  data(){
	return{
	  form: {
		force: false,
		row_start: 1,
	  },
	  upload: {
	    file: [{ name: null }],
	  },
	  error: null,
	}
  },
  async created () {
	
  },
  methods: {
	async save(){
	  let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/lead-contacts/import'
	  
	  let data = new FormData()
		const uploadKeys = Object.keys(this.upload)
	  
		for (let z = 0; z < uploadKeys.length; z++) {
		  const key = uploadKeys[z]
		  
		  for (let i = 0; i < this.upload[key].length; i++) {
			if (this.upload[key][i].name !== null) {
			  let f = {}
			  if (this.upload[key][i].type == 'server') {
				f = JSON.stringify(this.upload[key][i])
			  } else {
				data.append(key + '_names[' + i + ']', this.upload[key][i].name)
				f = this.upload[key][i].file
			  }
			  data.append(key, f)
			}
		  }
		}
		
		data.append('force', this.form.force ? 1 : 0);
		data.append('row_start', this.form.row_start);
	    
	  	await axios.post(url, data, {
			headers: {
			  'Content-Type': 'multipart/form-data',
			}
		  }).then((response) => {
			this.close();
			
			this.$noty.success('You successfully import Lead Contacts! Was imported ' + response.data.imported_count + ' records')
			
		  	this.$store.dispatch('editItemFromTable', {
				table: this.options.table,
				actionType: this.options.actionType,
		  	})
		}).catch((errors) => {
		  console.dir(errors)
		})
	},
	getUploadedFiles(el) {
	  return this.upload[el].filter(e => e.name !== null)
	},
	addInput(event, name, key) {
	  if (this.$refs[name][key].files.length) {
		const file_name = this.$refs[name][key].files[0].name
		if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
		  this.$noty.error('Error! A file with this name has already been added.')
		  return false
		}
	
		const extension = this.$refs[name][key].files[0].name.split('.').pop()
		const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
		if (!availableExtensionsArr.includes(extension.toLowerCase())) {
		  this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
		  return false
		}
	
		if (this.$refs[name][key].files[0].size / 1024 / 1024 > 10) {
		  this.$noty.error('File is too large. Maximum - 10MB.')
		  return false
		}
	
		this.upload[name] = this.upload[name].filter(e => e.name !== null)
		if (this.$refs[name].length < 10) {
		  this.upload[name].push({ name: file_name, file: event.target.files[0]})
		} else {
		  this.$noty.error('Maximum - 10 files')
		}
	  }
	},
	changeState(property){
	  this.form[property] = !this.form[property]
	},
	close() {
	  this.$emit("close");
	}
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
	width: 40rem;
  }

  &__header {
	padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
	flex-direction: column;
	align-content: flex-start;

	.row {
	  width: 100%;
	  display: flex;
	  align-items: center;
	  padding: .25rem 0;
	  margin: .25rem 0 0;

	  .label {
		min-width: 10rem;
		padding-right: .75rem;
		font-weight: bold;
		max-width: 160px;
	  }

	  .data {
		flex-grow: 1;

		.inpt {
		  width: 100%;
		  color: #000000;

		  &__ta {
			height: 7rem;
		  }
		}
		.btn {
		  padding: 0.5rem;
		  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
		  border-radius: 6px;
		  color: #fff;
		  font-size: 13px;
		}
		.delete-btn{
		  margin-left: auto;
		  width: 24px;
		  height: 24px;
		  background-color: #B03636;
		  border-radius: 50%;
		  display: flex;
		  align-items: center;
		  justify-content: center;
		  cursor: pointer;
		  svg{
			fill: #fff;
			max-width: 16px;
			max-height: 16px;
		  }
		}
	  }

	  &__price {
		.data {
		  display: flex;

		  input {
			margin-right: .75rem;
		  }

		  select {
			color: white;
			background-color: var(--c_more-black);
			padding: .25rem .5rem;
			border-radius: 6px;
		  }
		}
	  }
	}
	.row{
	  &_files {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 8px;

		.box_addFiles {
		  width: 30%;
		  //width: calc(50% - .75rem);
		  //margin: .75rem 0;
		  //padding: 1rem 1.75rem;
		  //background: #22252B;
		  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
		  display: flex;
		  flex-direction: column;

		  .ttl {
			padding-bottom: .25rem;
		  }

		  .separ {
			width: 3rem;
			height: .25rem;
			background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
			box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
			border-radius: 14px;
			margin: .25rem 0;
		  }

		  .content {
			padding: 1rem;
			flex-grow: 1;
			//margin-top: 1rem;
			border: 1px dashed #1763fb;
			box-sizing: border-box;
			filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
			border-radius: 14px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			.choose {
			  text-align: center;

			  .btn {
				padding: 0.5rem;
				background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
				border-radius: 6px;
				color: #fff;
				font-size: 13px;
			  }

			  .desc {
				font-size: 12px;
				color: rgba(255, 255, 255, 0.3);
			  }
			}

			&_many {
			  flex-wrap: wrap;
			  align-items: flex-start;
			  justify-content: space-between;
			  padding: 0.75rem 6px;

			  .draggable {
				display: flex;
				padding: 0;
				flex-wrap: wrap;
			  }

			  .file {
				position: relative;
				text-align: center;
				width: 4.5rem;
				max-width: 4.5rem;

				svg.img {
				  width: 3rem;
				  height: 3rem;
				  margin: 0 auto;
				  cursor: pointer;
				  fill: #1763fb;
				  path{
					fill: #1763fb;
				  }
				}
				.btn {
				  &_del {
					position: absolute;
					top: 0;
					right: 1rem;
					width: 1.25rem;
					height: 1.25rem;
					border-radius: 50%;
					background-color: #B03636;
					display: flex;
					align-items: center;
					justify-content: center;

					svg.img {
					  width: 40%;
					  height: 40%;
					}
				  }

				  &_download {
					position: absolute;
					top: 1.7rem;
					right: 1rem;
					width: 1.25rem;
					height: 1.25rem;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;

					svg.img {
					  width: 90%;
					  height: 90%;
					}
				  }
				}

				.desc {
				  font-size: 10px;
				  color: #333333;
				  word-break: break-word;
				}
			  }

			  .add {
				text-align: center;
				width: 2.5rem;
				max-width: 2.5rem;

				.btn {
				  width: 2.5rem;
				  height: 2.5rem;
				  border-radius: 50%;
				  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
				  display: flex;
				  align-items: center;
				  justify-content: center;

				  svg.img {
					width: 45%;
					height: 45%;
					transform: rotate(45deg);
				  }
				}

				.desc {
				  font-size: 12px;
				  color: rgba(255, 255, 255, 0.3);
				}
			  }
			}
		  }
		}
	  }
	}
  }
  &__footer {
	padding: 1rem 1.5rem 1.5rem;
  }
  textarea{
	height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
</style>
