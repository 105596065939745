var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'sidebar-show': _vm.showSidebar },attrs:{"id":"sidebar"}},[_c('div',{staticClass:"btn btn_burger",class:{ 'btn_burger-show': _vm.showSidebar },on:{"click":function($event){_vm.showSidebar = !_vm.showSidebar}}},[(!_vm.showSidebar)?_c('svg',{staticStyle:{"enable-background":"new 0 0 384 384"},attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 384 384","xml:space":"preserve"}},[_c('rect',{attrs:{"x":"0","y":"277.333","width":"384","height":"42.667"}}),_c('rect',{attrs:{"x":"0","y":"170.667","width":"384","height":"42.667"}}),_c('rect',{attrs:{"x":"0","y":"64","width":"384","height":"42.667"}})]):_c('Close')],1),_c('div',{staticClass:"header",class:{ 'header-show': _vm.showSidebar }},[_c('Logo',{staticClass:"logo"})],1),_c('div',{staticClass:"main",class:{ 'main-show': _vm.showSidebar }},[_c('div',{staticClass:"avatar"},[_c('div',{staticClass:"desc"},[_vm._v(" Hello, "+_vm._s((_vm.$store.state.user) ? _vm.$store.state.user.member_login : '')+" ")]),(_vm.$store.state.user && _vm.$store.state.user.is_super_admin)?_c('router-link',{staticClass:"btn btn_balance",attrs:{"to":{ name: 'Balance' }}},[_vm._v(" Balance ")]):_vm._e()],1),_c('div',{staticClass:"links"},_vm._l((_vm.links),function(item,key){return _c('router-link',{key:key,staticClass:"item",class:{
          'item-active': _vm.$route.name == item.routeName,
          'soon': item.сomingSoon,
          'bright': item.bright,
          'color-green': item.color == 'green',
          'color-green-text': item.color == 'green-text',
          'color-blue': item.color == 'blue',
        },attrs:{"to":{ name: item.routeName }},nativeOn:{"click":function($event){_vm.showSidebar = false}}},[_c(item.imgComponent,{tag:"component",staticClass:"img"}),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))]),_c('span',{staticClass:"soon_label"},[_vm._v("Coming soon")])],1)}),1),_c('div',{staticClass:"banner",on:{"click":_vm.openChat}},[_c('span',{staticClass:"dots dots-top"},[_c('Dots')],1),_c('span',{staticClass:"dots dots-bottom"},[_c('Dots')],1),_vm._m(0),_c('div',{staticClass:"btn"},[_c('Arrow',{staticClass:"img"})],1)])]),_c('div',{staticClass:"footer",class:{ 'footer-show': _vm.showSidebar }},[_vm._v(" "+_vm._s(_vm.$store.getters.contentArr.all_rights_reserved)+" ")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',[_vm._v("N5 Touch")]),_c('div',[_vm._v("Chat")])])}]

export { render, staticRenderFns }