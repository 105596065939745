import { render, staticRenderFns } from "./LeadContactModal.vue?vue&type=template&id=06a73907&scoped=true&"
import script from "./LeadContactModal.vue?vue&type=script&lang=js&"
export * from "./LeadContactModal.vue?vue&type=script&lang=js&"
import style0 from "./LeadContactModal.vue?vue&type=style&index=0&id=06a73907&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06a73907",
  null
  
)

export default component.exports