<template>
  <overlay-scrollbars class="page page-personal" ref="os">
    <div class="box-container">
      <div class="transparent-box box_b-info">
        <div class="content">
          <div class="row person-info">
            <div>
              <div @click="createLeadContact" class="btn create-account-btn" style="margin-right: 12px">Create</div>
              <div @click="importFile" class="btn create-account-btn">Import</div>
            </div>
          </div>
          <div class="row person-info filter">
            <div class="col">
              <input 
                name="search_query" 
                v-model="search_query" 
                class="inpt" 
                placeholder="Enter to search" 
                @change="getLeadContacts"
                @keyup.enter="getLeadContacts" 
              />
            </div>
            <div class="col">
              <select name="country" class="inpt" v-model="search_country" @change="getLeadContacts">
                <option :value="null">Select country</option>
                <option 
                  v-for="(country, i) in countries" 
                  :value="country.id"
                >
                  {{ country.name }}
                </option>
              </select>
            </div>
            <div class="col">
              <select name="manager_name" class="inpt" v-model="search_manager" @change="getLeadContacts">
                <option :value="null">Select manager</option>
                <option 
                  v-for="(manager, i) in managers" 
                  :value="manager.name"
                >
                  {{ manager.name }}
                </option>
              </select>
            </div>
            <div class="col">
              <select name="status" class="inpt" v-model="search_status" @change="getLeadContacts">
                <option :value="null">Select status</option>
                <option 
                  v-for="(status, i) in statuses"
                  :value="status"
                >
                  {{ status }}
                </option>
              </select>
            </div>
            <div class="col">
              <div class="btn" @click="getLeadContacts">
                Search
              </div>
            </div>
          </div>

          <div class="block">
            <Table
                @editItem="editItem"
                @deleteItem="deleteItem"
                @approveItem="approveToList"
                @toggleProperty="toggleProperty"
                :key="leadContactsTableKey"
                :options="leadContactsTableData"
                @change-page="getLeadContacts"
            />
          </div>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>
  
<script>
  import Pen from '../components/img/Pen.vue'
  import User from '../components/img/User.vue'
  import Table from '../components/elem/Table.vue'
  import Pagination from 'laravel-vue-pagination'
  import Tmblr from '../components/elem/Tmblr.vue'
  
  export default {
    components: {
      Pen,
      User,
      Pagination,
      Table,
      Tmblr
    },
    data(){
        return {
            leadContactsTableKey: 0,
            leadContactsTableData: {
                objName: 'leadContacts',
                colsWidth: ['5%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '5%'],
                header: [
                  { ttl: '#', info: null },
                  { ttl: 'Country', info: null },
                  { ttl: 'Company Name', info: null },
                  { ttl: 'Type of Service', info: null},
                  { ttl: 'Type of Provider', info: null },
                  { ttl: 'Channel', info: null },
                  { ttl: 'Contacts', info: null},
                  { ttl: 'Status', info: null},
                  { ttl: 'First Call Date', info: null},
                  { ttl: 'Price', info: null },
                  { ttl: 'Manager name', info: null },
                  { ttl: '', info: null },
                ],
                body: [],
                paginationData: null,
                sort: {
                    name: '',
                    order: 'desc',
                    loading: false
                }
            },
            search_query: null,
            search_manager: null,
            search_status: null,
            search_country: null,
            managers: [],
            countries: [],
            statuses: [
              'Active',
              'In Progress',
              'Push',
              'Lost',
              'Close win',
              'Not active',
              'Clarification needed',
              'Hold',
              'Closed'
            ]
        }
    },
    async created () {
      await this.getManagers()
      await this.getCountries()
      await this.getLeadContacts()
    },
    methods: {
      async getCountries () {
        await axios.get('admin/data/countries')
        .then(({data}) => {
          this.countries = data
        })
        .catch((error) => {
          console.log(error);
        })
      },
      async getManagers () {
        const that = this
        await axios.get('admin/data/managers')
          .then(function (response) {
            that.managers = response.data
          })
          .catch(function (error) {
            console.log(error);
          })
      },
      async filterLeadContacts(){
        this.showBreakedDeadline = !this.showBreakedDeadline

        await this.getLeadContacts()
      },
      async getLeadContacts(page = 1){
        const that = this

        const basePath = process.env.VUE_APP_BACKEND_API_URL + 'admin/lead-contacts?page=' + page + '&per_page=15'

        const sortParams = '&sort=' + this.leadContactsTableData.sort.name + '&order=' + this.leadContactsTableData.sort.order

        let filterParams = ''

        filterParams += this.search_query ? '&search=' + this.search_query : ''

        filterParams += this.search_manager ? '&manager=' + this.search_manager : ''

        filterParams += this.search_country ? '&country=' + this.search_country : ''
        
        filterParams += this.search_status ? '&status=' + this.search_status : ''

        await axios.get(basePath + sortParams + filterParams)
          .then(function (response) {
            that.leadContactsTableData.paginationData = response.data.data

            that.leadContactsTableData.body = []

            that.breakedDeadline = response.data.breaked_deadline

            response.data.data.data.forEach(el => {
              that.leadContactsTableData.body.push([
                '№ ' + el.id + '|' + el.external_excel_id,
                el.country ? el.country.name : '',
                {type: 'link', link: el.web_site, text: el.company_name },
                el.service_type,
                el.provider_type,
                el.channel,
                {
                  type: 'contacts', 
                  items: [
                    {link: 'mailto:' + el.email, text: el.email },
                    {link: el.linkedin, text: 'LinkedIn' }
                  ],
                  contact_data: el.contact_data
                },
                {type: 'lead_contacts_status', value: el.status},
                that.getFormattedDate(el.first_call_date),
                el.price,
                el.manager_name,
                { type: 'edit-delete-approve-toggle-top-actions', id: el.id, is_top: el.is_top, business_offer_id: el.business_offer_id }
              ])
            })

            if(that.leadContactsTableData.sort.name) that.leadContactsTableData.sort.loading = false
          })
          .catch((errors) => {
            console.dir(errors)
          })
      },
      async approveToList(obj){
        let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/lead-contacts/' + obj.id + '/approve-to-list'
        let that = this
        
        await axios.post(url).then(function (response){
          that.$store.dispatch('editItemFromTable', {
            id: obj.id,
            table: 'leadContacts',
            actionType: 'edit',
          })
        }) .catch(function (error) {
          console.log(error);
        })
      },
      getFormattedDate(value){
        if(value){
          const date = new Date(value);
          
          const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          });
          
          return formattedDate;
        }
        
        return '';
      },
      toggleProperty(obj){
        if(obj.id){
          let urlPart = '';
          
          if(obj.property == 'is_top'){
            urlPart = 'toggle-top';
          }
          
          let that = this
          
          axios.post('admin/lead-contacts/' + obj.id + '/' + urlPart)
          .then(function (response) {
            that.$store.dispatch('editItemFromTable', {
              id: obj.id,
              table: 'leadContacts',
              actionType: 'edit',
            })
          })
          .catch(function (error) {
            console.log(error);
          })
        }
      },
      deleteItem(obj){
        this.$store.commit('setModal', {
          template: 'delete',
          options: {
            id: obj.id,
            table: 'leadContacts',
            type: 'leadContact',
            url: 'admin/lead-contacts/' + obj.id,
            successfullMessage: 'You successfully deleted Lead Contact',
          }
        })
      },
      editItem(obj){
        this.$store.commit('setModal', {
          template: 'leadContact',
          options: {
            form: obj,
            id: obj.id,
            actionType: 'edit',
            table: 'leadContacts',
            objectType: 'leadContact',
            successfullMessage: 'You successfully updated Lead Contact',
          }
        })
      },
      createLeadContact(){
        this.$store.commit('setModal', {
          template: 'leadContact',
          options: {
            actionType: 'create',
            table: 'leadContacts',
            objectType: 'leadContact',
            successfullMessage: 'You successfully create Lead Contact',
          }
        })
      },
      importFile(){
        this.$store.commit('setModal', {
          template: 'importLeadContacts',
          options: {
            actionType: 'import',
            table: 'leadContacts',
            objectType: 'leadContact',
            successfullMessage: 'You successfully import Lead Contacts',
          }
        })
      },
    },
    watch: {
      '$store.state.deleteItemFromTableData': function(data) {
        let page = this.leadContactsTableData.paginationData.current_page

        this.getLeadContacts(page)
      },
      '$store.state.editItemFromTableData': function(data) {
        let page = this.leadContactsTableData.paginationData.current_page

        this.getLeadContacts(page)
      },
      '$store.state.createItemInTable': function(data) {
        let page = this.leadContactsTableData.paginationData.current_page

        this.getLeadContacts(page)
      },
      '$store.state.user': function(data){
        let page = this.leadContactsTableData.paginationData.current_page

        this.getLeadContacts(page)
      }
    }
  }
</script>
  
<style lang="scss" scoped>
  .page-personal {
    .box {
      &-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }
  
      &_rate {
        position: relative;
        height: 7rem;
        height: 11vh;
        margin-top: 6rem;
        margin-top: 7vh;
        margin-bottom: 4rem;
        margin-bottom: 6vh;
  
        .box-inside {
          width: 95%;
          height: 11rem;
          height: calc(100% + 6vh);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: linear-gradient(113.24deg, #1763fb 16.01%, #1763fb 106.71%);
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          border-radius: 14px;
          display: flex;
  
          .content {
            flex-grow: 1;
  
            .scale-box {
              width: 100%;
            }
          }
  
          .img {
            height: 14rem;
            height: calc(100% + 6vh);
            position: relative;
            top: 100%;
            transform: translateY(-96%);
            margin: auto 3rem;
            margin: auto 3vw;
          }
        }
      }
  
      &_my-profile {
        position: relative;
        width: 100%;
        padding-right: 4.25rem;
  
        .header {
          display: flex;
  
          .title {
            flex-grow: 1;
          }
  
          .tmblr {
            display: flex;
            align-items: center;
            padding: 0 .25rem;
  
            .txt {
              color: rgba(255, 255, 255, 0.5);
            }
  
            .btn {
              &-tmblr {
                position: relative;
                width: 1.5rem;
                height: .5rem;
                background-color: rgba(255, 255, 255, 0.12);
                border-radius: 2rem;
                margin: auto 1rem;
  
                &-l::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translate(-50%, -50%);
                  width: 1rem;
                  height: 1rem;
                  border-radius: 50%;
                  background-color: #ffffff;
                }
  
                &-r::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translate(50%, -50%);
                  width: 1rem;
                  height: 1rem;
                  border-radius: 50%;
                  background-color: #ffffff;
                }
              }
            }
  
            &-hide {
              .txt {
                color: #1763fb;
              }
  
              .btn-tmblr-r::before {
                background-color:  #1763fb;
              }
            }
          }
        }
  
        .content {
          padding: 0 0 1rem 0;
          padding: 0 0 1.5vh 0;
          display: flex;
  
          &.blur {
            filter: blur(7px);
          }
  
          .card {
            &-big {
              width: 100%;
              height: 11.5rem;
              display: flex;
              align-items: flex-end;
              background-image: url('/img/two_line.png');
              background-position: 0 0;
              background-repeat: no-repeat;
              background-size: contain;
              padding: 1.25rem 1.75rem;
  
              svg.img {
                width: 3.5rem;
                height: 4rem;
                margin-right: 1rem;
              }
  
              .data {
                flex-grow: 1;
  
                .ttl {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.5);
                  padding-bottom: .25rem;
                }
  
                .desc {
                  font-size: 1.25rem;
                  font-weight: 600;
                }
              }
            }
          }
  
          .col {
            &-1 {
              width: 27rem;
              width: 21vw;
              padding-right: .5rem;
            }
  
            &-2 {
              flex-grow: 1;
              padding-left: .5rem;
              padding-right: .5rem;
              display: flex;
  
              .subcol {
                padding: 0 .5rem;
  
                &-1 {
                  width: 30%;
                }
  
                &-2,
                &-3 {
                  width: 35%;
                }
              }
            }
          }
        }
      }
  
      &_b-info {
        flex-grow: 1;
        position: relative;
        // background-color: #272E35;
        margin-right: 2rem;
  
        .person-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 12px;
          &.filter{
            margin-bottom: 12px;
            justify-content: flex-start !important;
          }
        }
  
        .header {
          padding-right: 4.25rem;
  
          .title {
            &::before {
              background-color: rgb(255 255 255 / 25%);
            }
          }
        }
  
        .content {
          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
  
            .card {
              background: rgba(23, 99, 251, 0.1);
              width: 30%;
  
              &-min {
                width: 20%;
              }
            }
          }
        }
      }
  
      &_comp-logo {
        width: 23rem;
        position: relative;
        background-color: #272E35;
        overflow: hidden;
        padding-bottom: 1rem;
  
        .dots {
          position: absolute;
  
          &-top {
            top: -5px;
            right: 7px;
            width: 2rem;
            opacity: .5;
          }
  
          &-left {
            top: 6.5rem;
            left: 28px;
            width: 1.5rem;
            opacity: .5;
          }
  
          &-bottom {
            bottom: -12px;
            right: 1.75rem;
            width: 2.5rem;
            transform: rotate(90deg);
            opacity: 0.5;
          }
        }
  
        .header {
          .title {
            &::before {
              background-color: rgb(255 255 255 / 25%);
            }
          }
        }
  
        .logo {
          flex-grow: 1;
          width: 14rem;
          height: 14rem;
          margin: .25rem auto;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 50%;
          display: flex;
          align-items: flex-end;
          justify-content: space-around;
          position: relative;
          z-index: 1;
  
          .btn {
            width: 3.25rem;
            height: 3.25rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
  
            &_update {
              background-color: #1763fb;
  
              svg {
                width: 1.75rem;
                height: 1.75rem;
              }
            }
  
            &_remove {
              background-color: #B03636;
  
              svg {
                width: 1.25rem;
                height: 1.25rem;
              }
            }
          }
        }
      }
    }
  }
  
  .scale {
    position: relative;
    width: 100%;
    height: 1rem;
    margin: 1rem 0 3.5rem 0;
    border-radius: 3rem;
    background-color: rgba(255, 255, 255, 0.12);
  
    .progress {
      position: relative;
      height: 100%;
      border-radius: 3rem;
      background-color: #ffffff;
      background-image: url('/img/rate_el.png');
      background-position: 0 0;
      background-repeat: repeat-x;
      background-size: auto 100%;
  
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 5rem;
        background-color: #E2F4EC;
        background-image: url('/img/smile.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 65%;
      }
  
      .note {
        position: absolute;
        bottom: -3.25rem;
        right: 0;
        transform: translateX(50%);
        background-color: #094F4D;
        color: #ffffff;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 7px 1rem 6px;
        border-radius: 6px;
  
        &::before {
          content: '';
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          width: .75rem;
          height: .75rem;
          background-color: #094F4D;
          border-radius: 2px;
        }
      }
    }
  }
  .btn{
    border-radius: 0.5rem; 
    padding: 12px 24px; 
    border: 1px solid #1763fb;
    background: #1763fb;
    display: inline-block;
  }
  .btn {
    &-edit {
      width: 2.75rem;
      height: 2.75rem;
      position: absolute;
      top: .75rem;
      right: .75rem;
      background: #22252B;
      border-radius: 6px;
      padding: .75rem;
      display: flex;
      align-items: center;
      justify-content: center;
  
      svg.img {
        width: 1rem;
        height: 1rem;
      }
    }
    &-save {
      width: 3.75rem;
      height: 2.75rem;
      position: absolute;
      top: .75rem;
      right: .75rem;
      background: #22252B;
      border-radius: 6px;
      padding: .75rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
  
      svg.img {
        width: 1rem;
        height: 1rem;
      }
    }
  }
  
  .form-control {
    width: 100%;
    height: 100%;
    color: #ffffff;
    background-color: #1C2126;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin: auto 0rem;
    border: 1px solid #2F363D;
    border-radius: .75rem;
    box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
    outline: none;
    -webkit-appearance: none;
  
    &::placeholder {
      color: #B9B9B9;
    }
  }
  
  .invalid-feedback {
      width: 100%;
      margin-top: .25rem;
      font-size: 80%;
      color: #dc3545;
  }
  
  .transparent-box {
    width: 100%;
    border-radius: 14px;
    margin: 1rem 0;
  }
  
  .excel-btn-wrapper {
    position: relative;
    height: 67px;
  }
  
  .excel-btn {
    border-radius: 0.5rem; 
    padding: 10px; 
    margin-left: 30px; 
    border: 1px solid #1763fb;
    display: inline-block;
    position:absolute;
    right: 15px;
    top: 0;
  }
  
  .create-account-btn {
    border-radius: 1rem;
    padding: 10px;
    background-color: #1763fb;
  }
  .breaked-deadline{
    color: red;
    display: flex;
    justify-content: space-between;
  }
  </style>
  