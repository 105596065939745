<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Add Asset for Sell</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Offer type</div>
        <div class="data">
          <select name="offer_type" class="inpt" v-model="form.offer_type">
            <option :value="null">Select offer</option>
            <option
              v-for="type in offerTypes" :key="type"
              :selected="type === form.offer_type"
              :value="type"
            >
              {{ type }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Date</div>
        <div class="data">
          <input type="datetime-local" name="date" class="inpt" v-model="form.date">
        </div>
      </div>
      <div class="row">
        <div class="label">Type of Business</div>
        <div class="data">
          <input type="text" name="business_type" class="inpt" v-model="form.business_type">
        </div>
      </div>
      <div class="row">
        <div class="label">License type</div>
        <div class="data">
          <select name="license_type" class="inpt" v-model="form.license_type">
            <option :value="null">Select license type</option>
            <option
              v-for="type in licenseTypes" :key="type.id"
              :selected="type === form.license_type"
              :value="type"
            >
              {{ type }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">License name</div>
        <div class="data">
          <input type="text" name="license_name" class="inpt" v-model="form.license_name">
        </div>
      </div>
      <div class="row">
        <div class="label">License Number</div>
        <div class="data">
          <input type="text" name="license_number" class="inpt" v-model="form.license_number">
        </div>
      </div>
      <div class="row">
        <div class="label">Web site</div>
        <div class="data">
          <input type="text" name="web_site" class="inpt" v-model="form.web_site">
        </div>
      </div>
      <div class="row">
        <div class="label">Reg link</div>
        <div class="data">
          <input type="text" name="reg_link" class="inpt" v-model="form.reg_link">
        </div>
      </div>
      <div class="row">
        <div class="label">Public link</div>
        <div class="data">
          <input type="text" name="public_link" class="inpt" v-model="form.public_link">
        </div>
      </div>
      <div class="row">
        <div class="label">Country</div>
        <div class="data">
          <select name="country_id" class="inpt" v-model="form.country_id" @change="countrySelected">
            <option value="null">Select country</option>
            <option
              v-for="country in countries" :key="country.id"
              :selected="country.id === form.country_id"
              :value="country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Fintech type</div>
        <div class="data">
          <select name="fintech_type_id" class="inpt" v-model="form.fintech_type_id">
            <option value="null">Select type</option>
            <option
              v-for="type in fintechTypes" :key="type.id"
              :selected="type.id === form.fintech_type_id"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Managers</div>
        <div class="data">
          <div v-for="(formManager, formManagerIndex) in form.managers">
            <div>
              <div class="row">
                <div class="data">
                  <div class="row">
                    <div class="label">
                      Manager № {{ formManagerIndex + 1 }}
                    </div>
                    <div class="data">
                      <div class="delete-btn" @click="deleteManager(formManagerIndex)">
                        <Close class="img"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <select name="manager_name" class="inpt" v-model="formManager.name">
                <option :value="null">Select manager</option>
                <option 
                  v-for="(manager, i) in managers" 
                  :value="manager.name"
                >
                  {{ manager.name }}
                </option>
              </select>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addManager">
                  Add manager
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Description</div>
        <div class="data">
          <textarea name="description" rows="5" class="inpt inpt__ta" v-model="form.description"/>
        </div>
      </div>
      <div class="row">
        <div class="label">Long description</div>
        <div class="data">
          <button class="btn long-desc " @click="addLongDescription" v-if="!this.form.long_description">
            Add table description
          </button>
          <button class="btn long-desc delete" @click="deleteLongDescription" v-if="this.form.long_description">
            Delete table description
          </button>
        </div>
      </div>
      <div class="row">
        <div class="long-desc">
          <div class="row" v-for="(item, i) in this.form.long_description">
            <div class="label">
              {{ item.title }}
            </div>
            <div class="data">
              <input type="text" name="long_description" class="inpt" v-model="item.description">
            </div>
          </div>
        </div>
      </div>
      <div class="seller_wrap">
        <div class="row">
          <div class="label">Seller type</div>
          <div class="data">
            <select name="seller_type" class="inpt" v-model="form.seller_type">
              <option :value="null">Select seller type</option>
              <option
                v-for="type in sellerTypes" :key="type"
                :selected="type === form.seller_type"
                :value="type"
              >
                {{ type }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" v-if="!isEdit || !form.user">
          <div class="label">Email</div>
          <div class="data">
            <input type="text" name="customer_email" required class="inpt" v-model="form.customer_email">
          </div>
        </div>
        <div class="row" v-if="form.user">
          <div class="label">User</div>
          <div class="data">
            {{ form.user }}
          </div>
        </div>
        <div class="row" v-if="$store.state.user.is_super_admin">
          <div class="label">Seller name</div>
          <div class="data">
            <input type="text" name="seller_name" class="inpt" v-model="form.seller_name">
          </div>
        </div>
        <div class="row" v-if="$store.state.user.is_super_admin">
          <div class="label">Seller contacts</div>
          <div class="data">
            <textarea name="seller_contacts" rows="5" class="inpt inpt__ta" v-model="form.seller_contacts"/>
          </div>
        </div>
        <div class="row" v-if="$store.state.user.is_super_admin">
          <div class="label">Seller Number</div>
          <div class="data">
            <input type="text" name="seller_number" class="inpt" v-model="form.seller_number">
          </div>
        </div>
        <div class="row">
          <div class="label">Seller Price</div>
          <div class="data">
            <input type="text" name="wish_price" class="inpt" v-model="form.wish_price">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Agent fee</div>
        <div class="data">
          <input type="number" name="agent_fee" class="inpt" v-model="form.agent_fee">
        </div>
        <div style="flex: 0 0 20%; max-width: 20%; margin-left: 8px;">
          <div class="row row_files">
            <div class="box box_addFiles" v-if="$store.state.user.is_super_admin" style="width: 100%;">
              <div v-if="getUploadedFiles('agent_docs').length" class="content content_many" style="font-size: 9px;">
              <draggable v-model="upload.agent_docs" tag="div" class="draggable">
              <div v-for="(file, key) in getUploadedFiles('agent_docs')" :key="key" class="file">
                <BlueFile class="img" />
                <div class="btn btn_del" @click="delFile('agent_docs', key, file)"><Close class="img" /></div>
                <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'agent_docs')">
                <BlueDownload class="img" />
                </div>
                <div class="desc">{{ file.name }}</div>
              </div>
              </draggable>
              <div class="add">
                <div class="btn" @click="$refs.agent_docs[upload.agent_docs.length - 1].click()">
                <Close class="img" />
                </div>
              </div>
              </div>
              <div v-else class="content" style="font-size: 9px;">
              <div class="choose">
                <div class="btn" @click="$refs.agent_docs[0].click()" style="font-size: 9px;">Agent docs</div>
              </div>
              </div>
              <input
                v-for="(i, key) in upload.agent_docs"
                :key="key"
                type="file"
                :name="`agent_docs_${key}`"
                class="hide"
                ref="agent_docs"
                @change="addInput($event, 'agent_docs', key)"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row row__price">
        <div class="label">Price n5Bank</div>
        <div class="data">
          <input type="text" name="price" class="inpt" v-model="form.price">
          <select v-if="currencies" v-model="form.currency_id">
            <option
              v-for="c in currencies" :key="c.id"
              :value="c.id"
              v-text="c.code"
            />
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Top offer</div>
        <div class="data c-tmblr">
          <Tmblr :active="!!form.is_top" :class="'c-tmblr'" :text="'Top offer (with star)'" @changeState="changeState('is_top')" />
        </div>
      </div>
      <div class="row">
        <div class="label">Verified status</div>
        <div class="data c-tmblr">
          <Tmblr :active="!!form.is_verified" :class="'c-tmblr'" :text="'Verified'" @changeState="changeState('is_verified')" />
        </div>
      </div>
      <div class="row">
        <div class="label">KYB status</div>
        <div class="data c-tmblr">
          <Tmblr :active="!!form.is_kyb_done" :class="'c-tmblr'" :text="'KYB done'" @changeState="changeState('is_kyb_done')" />
        </div>
      </div>
      <div class="row">
        <div class="label">KYC status</div>
        <div class="data c-tmblr">
          <Tmblr :active="!!form.is_kyc_done" :style-class="'c-tmblr'" :text="'KYC done'" @changeState="changeState('is_kyc_done')" />
        </div>
      </div>
      <div class="row row_files">
        <div class="box box_addFiles" v-if="$store.state.user.is_super_admin">
          <div v-if="getUploadedFiles('seller_nda').length" class="content content_many">
          <draggable v-model="upload.seller_nda" tag="div" class="draggable">
          <div v-for="(file, key) in getUploadedFiles('seller_nda')" :key="key" class="file">
            <BlueFile class="img" />
            <div class="btn btn_del" @click="delFile('seller_nda', key, file)"><Close class="img" /></div>
            <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'seller_nda')">
            <BlueDownload class="img" />
            </div>
            <div class="desc">{{ file.name }}</div>
          </div>
          </draggable>
          <div class="add">
            <div class="btn" @click="$refs.seller_nda[upload.seller_nda.length - 1].click()">
            <Close class="img" />
            </div>
          </div>
          </div>
          <div v-else class="content">
          <div class="choose">
            <div class="btn" @click="$refs.seller_nda[0].click()">Seller NDA</div>
          </div>
          </div>
          <input
            v-for="(i, key) in upload.seller_nda"
            :key="key"
            type="file"
            :name="`seller_nda_${key}`"
            class="hide"
            ref="seller_nda"
            @change="addInput($event, 'seller_nda', key)"
          >
          <div v-if="!getUploadedFiles('seller_nda').length" class="file-template">
            <a href="https://api.n5bank.com/files/doc-templates/seller_nda.docx">
              <BlueDownload class="img" />
            </a>
          </div>
        </div>
        <div class="box box_addFiles" v-if="$store.state.user.is_super_admin">
          <div v-if="getUploadedFiles('seller_agreement_docs').length" class="content content_many">
            <draggable v-model="upload.seller_agreement_docs" tag="div" class="draggable">
            <div v-for="(file, key) in getUploadedFiles('seller_agreement_docs')" :key="key" class="file">
              <BlueFile class="img" />
              <div class="btn btn_del" @click="delFile('seller_agreement_docs', key, file)"><Close class="img" /></div>
              <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'seller_agreement_docs')">
                <BlueDownload class="img" />
              </div>
              <div class="desc">{{ file.name }}</div>
            </div>
            </draggable>
            <div class="add">
              <div class="btn" @click="$refs.seller_agreement_docs[upload.seller_agreement_docs.length - 1].click()">
                <Close class="img" />
              </div>
            </div>
          </div>
          <div v-else class="content">
            <div class="choose">
              <div class="btn" @click="$refs.seller_agreement_docs[0].click()">Seller contract</div>
            </div>
          </div>
            <input
              v-for="(i, key) in upload.seller_agreement_docs"
              :key="key"
              type="file"
              :name="`seller_agreement_docs_${key}`"
              class="hide"
              ref="seller_agreement_docs"
              @change="addInput($event, 'seller_agreement_docs', key)"
            >
            <div v-if="!getUploadedFiles('seller_agreement_docs').length" class="file-template">
              <a href="https://api.n5bank.com/files/doc-templates/agreement.docx">
                <BlueDownload class="img" />
              </a>
            </div>
        </div>
        <div class="box box_addFiles">
          <div v-if="getUploadedFiles('license_docs').length" class="content content_many">
            <draggable v-model="upload.license_docs" tag="div" class="draggable">
            <div v-for="(file, key) in getUploadedFiles('license_docs')" :key="key" class="file">
              <BlueFile class="img" />
              <div class="btn btn_del" @click="delFile('license_docs', key, file)"><Close class="img" /></div>
              <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'license_docs')"><BlueDownload class="img" /></div>
              <div class="desc">{{ file.name }}</div>
            </div>
            </draggable>
            <div class="add">
              <div class="btn" @click="$refs.license_docs[upload.license_docs.length - 1].click()"><Close class="img" /></div>
            </div>
          </div>
          <div v-else class="content">
            <div class="choose">
              <div class="btn" @click="$refs.license_docs[0].click()">License document</div>
            </div>
          </div>
            <input
              v-for="(i, key) in upload.license_docs"
              :key="key"
              type="file"
              :name="`license_docs_${key}`"
              class="hide"
              ref="license_docs"
              @change="addInput($event, 'license_docs', key)"
            >
        </div>
        <div class="box box_addFiles">
          <div v-if="getUploadedFiles('agreement_docs').length" class="content content_many">
            <draggable v-model="upload.agreement_docs" tag="div" class="draggable">
            <div v-for="(file, key) in getUploadedFiles('agreement_docs')" :key="key" class="file">
              <BlueFile class="img" />
              <div class="btn btn_del" @click="delFile('agreement_docs', key, file)"><Close class="img" /></div>
              <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'agreement_docs')"><BlueDownload class="img" /></div>
              <div class="desc">{{ file.name }}</div>
            </div>
            </draggable>
            <div class="add">
              <div class="btn" @click="$refs.agreement_docs[upload.agreement_docs.length - 1].click()"><Close class="img" /></div>
            </div>
          </div>
          <div v-else class="content">
            <div class="choose">
              <div class="btn" @click="$refs.agreement_docs[0].click()">Agreement documents</div>
            </div>
          </div>
            <input
              v-for="(i, key) in upload.agreement_docs"
              :key="key"
              type="file"
              :name="`agreement_docs_${key}`"
              class="hide"
              ref="agreement_docs"
              @change="addInput($event, 'agreement_docs', key)"
            >
        </div>
        <div class="box box_addFiles">
          <div v-if="getUploadedFiles('deal_docs').length" class="content content_many">
            <draggable v-model="upload.deal_docs" tag="div" class="draggable">
            <div v-for="(file, key) in getUploadedFiles('deal_docs')" :key="key" class="file">
              <BlueFile class="img" />
              <div class="btn btn_del" @click="delFile('deal_docs', key, file)"><Close class="img" /></div>
              <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'deal_docs')"><BlueDownload class="img" /></div>
              <div class="desc">{{ file.name }}</div>
            </div>
            </draggable>
            <div class="add">
              <div class="btn" @click="$refs.deal_docs[upload.deal_docs.length - 1].click()"><Close class="img" /></div>
            </div>
          </div>
          <div v-else class="content">
            <div class="choose">
              <div class="btn" @click="$refs.deal_docs[0].click()">Full deal documents</div>
            </div>
          </div>
            <input
              v-for="(i, key) in upload.deal_docs"
              :key="key"
              type="file"
              :name="`deal_docs_${key}`"
              class="hide"
              ref="deal_docs"
              @change="addInput($event, 'deal_docs', key)"
            >
        </div>
      </div>
      <span v-if="error && error.length > 0" class="invalid-feedback">{{ error }}</span>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Close from './../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import BlueFile from './../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from './../img/BlueDownload.vue'

export default {
  components: {
    Close,
    Tmblr,
    BlueDownload,
    BlueFile,
    draggable,
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    countries: [],
    managers: [],
    licenseTypes: [
        "Crypto/VASP/CASP",
        "Money service/Remittee",
        "API",
        "AEMI",
        "MSO",
        "MSB",
        "MPI",
        "Small Payment Institution",
        "Payment Institution",
        "Small EMI",
        "EMI",
        "SRO",
        "PSP (AISP, PISP)",
        "Bank",
        "VC",
        "Fund",
        "Asset management",
        "Under management",
        "Broker/Dealer/Forex"
    ],
    offerTypes: [
        "Ready",
        "Scretch"
    ],
    sellerTypes: [
        "Owner",
        "Broker"
    ],
    managerMap: {
      'Canada': 'EGOR',
      'United States': 'IHOR',
      'Poland': 'VLADIMIR',
      'Hong Kong': 'DEN',
      'Puerto Rico': 'VLADIMIR',
      'Dominica': 'VLADIMIR',
      'Comoros': 'EGOR',
      'Switzerland': 'EGOR',
      'Singapore': 'DEN',
      'United Kingdom': 'VLADIMIR',
      'Netherlands': 'DEN',
      'United Arab Emirates': 'VLADIMIR',
      'Malta': 'IHOR',
    },
    defaultManager: 'MARYNA',
    allCurrencies: null,
    fintechTypes: [],
    form: {
      business_type: null,
      license_type: null,
      country_id: null,
      fintech_type_id: null,
      description: null,
      long_description: null,
      price: null,
      wish_price: null,
      seller_name: null,
      seller_contacts: null,
      license_number: null,
      customer_email: null,
      is_top: false,
      is_verified: false,
      is_kyb_done: false,
      is_kyc_done: false,
      currency_id: null,
      user: null,
      manager_name: null,
      seller_number: null,
      date: null,
      managers: [],
    },
    default_long_description: [
      {
        title: 'License Category : (Bank, EMI, Payment Institutio , Crypto, Forex Broker, Investment Fund)',
        description: '',
      },
      {
        title: 'License Name:',
        description: '',
      },
      {
        title: 'Years active:',
        description: '',
      },
      {
        title: 'Country of Issuance:',
        description: '',
      },
      {
        title: 'Regulatory Authority:',
        description: '',
      },
      {
        title: 'Brief Description:',
        description: '',
      },
      {
        title: 'Scope of Activities and Allowed Services:',
        description: '',
      },
      {
        title: 'Current Status:',
        description: '',
      },
      {
        title: 'License Validity Term:',
        description: '',
      },
      {
        title: 'Additional Conditions and Requirements:',
        description: '',
      },
      {
        title: 'What is included:',
        description: '',
      },
      {
        title: 'Operational accounts: ',
        description: '',
      },
      {
        title: 'Safeguarding/correspondence accounts:',
        description: '',
      },
      {
        title: 'Can make clients payments via SEPA:',
        description: '',
      },
      {
        title: 'Can make clients payments via SWIFT (cpecify currencyes):',
        description: '',
      },
      {
        title: 'Can issue own dedicated IBANs for SEPA/SWIFT:',
        description: '',
      },
      {
        title: 'Does the company has it\'s own SWIFT (Yes/No):',
        description: '',
      },
      {
        title: 'Does the company has any BAAS solutions:',
        description: '',
      },
      {
        title: 'Can issue 3th party provider dedicated IBANs:',
        description: '',
      },
      {
        title: 'Does the company has any Crypto related capability:',
        description: '',
      },
      {
        title: 'Staff willing to stay Yes/No:',
        description: '',
      },
      {
        title: 'Software (own or SAAS):',
        description: '',
      },
      {
        title: 'If Software own, who owns the IP rights:',
        description: '',
      },
      {
        title: 'Software included in sale price (Yes/No):',
        description: '',
      },
      {
        title: 'Acquiring providers:',
        description: '',
      },
      {
        title: 'Payment cards providers:',
        description: '',
      },
      {
        title: 'Any principal memberships for card issuing:',
        description: '',
      },
      {
        title: 'Any principal memberships for card acquiring:',
        description: '',
      },
      {
        title: 'Any BIN sponsorships for card issuing:',
        description: '',
      },
      {
        title: 'Any BIN sponsorships for card acquiring:',
        description: '',
      },
      {
        title: 'Other Features:',
        description: '',
      },
      {
        title: 'Contact Information:',
        description: '',
      },
    ],
    error: null,
    message: '',
    upload: {
      license_docs: [{ name: null }],
      agreement_docs: [{ name: null }],
      deal_docs: [{ name: null }],
      seller_agreement_docs: [{name: null}],
      seller_nda: [{name: null}],
      agent_docs: [{name: null}]
    }
  }),
  computed: {
    isEdit () {
      return this.options && this.options.id && this.options.form
    },
    currencies () {
      if (!this.allCurrencies) return []

      return this.allCurrencies.filter(el => el.code === 'EUR' || el.code === 'USD')
    }
  },
  async created () {
    this.getCurrencies()
    await this.getFintechTypes()
    await this.getCountries()
    await this.getManagers()
    
    if(this.isEdit){
      this.getLicenseDocs()
      this.getSellerNda()
      this.getAgreementDocs()
      this.getDealDocs()
      this.getAgentDocs()
      this.getSellerAgreementDocs()
    }
  },
  methods: {
    ...mapActions({
      createBusinessOffer: 'bos_business/createOffer',
      updateBusinessOffer: 'bos_business/updateOffer'
    }),
    countrySelected(){
      let country = this.countries.find(el => el.id == this.form.country_id)
      
      if(country){
        let manager = this.managerMap[country.name] ?? this.defaultManager
        
        this.form.managers = [{
          name: manager
        }]
      }
      else{
        if(!this.form.manager_name){
          this.form.managers = []
        }
      }
    },
    changeState(property){
      this.form[property] = !this.form[property]
    },
    async getManagers () {
      const that = this
      await axios.get('admin/data/managers')
        .then(function (response) {
          that.managers = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    addManager(){
      this.form.managers.push({
        name: null
      })
    },
    deleteManager(index){
      this.form.managers.splice(index, 1)
    },
    updateForm () {
      if (!this.isEdit) return

      this.form.business_type = this.options.form.business_type
      this.form.license_type = this.options.form.license_type
      this.form.description = this.options.form.description
      this.form.price = this.options.form.price.price ? this.options.form.price.price : 0
      this.form.wish_price = this.options.form.price.wish_price ? this.options.form.price.wish_price : 0
      this.form.currency_id = this.options.form.price.currency ? this.options.form.price.currency.id : 2
      this.form.license_number = this.options.form.license_number
      this.form.is_verified = this.options.form.is_verified
      this.form.long_description = this.options.form.long_description
      this.form.seller_name = this.options.form.seller_name
      this.form.seller_contacts = this.options.form.seller_contacts
      this.form.is_kyb_done = this.options.form.is_kyb_done
      this.form.is_kyc_done = this.options.form.is_kyc_done
      this.form.customer_email = this.options.form.customer_email
      this.form.user = this.options.form.user
      this.form.is_top = this.options.form.is_top
      this.form.manager_name = this.options.form.manager_name
      this.form.seller_number = this.options.form.seller_number
      this.form.date = this.options.form.date
      this.form.reg_link = this.options.form.reg_link
      this.form.license_name = this.options.form.license_name
      this.form.public_link = this.options.form.public_link
      this.form.web_site = this.options.form.web_site
      this.form.offer_type = this.options.form.offer_type
      this.form.seller_type = this.options.form.seller_type
      this.form.agent_fee = this.options.form.agent_fee

      const country = this.countries.find(el => el.name === this.options.form.country_name)
      this.form.country_id = country && country.id ? country.id : null

      const type = this.fintechTypes.find(el => el.name === this.options.form.fintech_type_name)
      this.form.fintech_type_id = type && type.id ? type.id : null
      
      let arrManagers = [];
      
      if(this.form.manager_name){
        arrManagers = this.form.manager_name.split('|').map(name => {
          return { name: name };
        });
      }
      
      this.$set(this.form, 'managers',  arrManagers)
    },
    getLicenseDocs () {
      const that = this
      const id = this.options.id
      
      if(id){
        axios.get('admin/business-offers/' + id + '/get-file/license_docs')
        .then((response) => {
          let licenseDocsData = response.data
          
          if (licenseDocsData.length > 0) {
            that.upload.license_docs = licenseDocsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    getAgreementDocs () {
      const that = this
      const id = this.options.id
      
      if(id){
        axios.get('admin/business-offers/' + id + '/get-file/agreement_docs')
        .then((response) => {
          let agreementDocsData = response.data
          /*agreementDocsData = agreementDocsData.sort(function (a, b) {
            return a.order - b.order
          })*/
          if (agreementDocsData.length > 0) {
            that.upload.agreement_docs = agreementDocsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    getDealDocs () {
      const that = this
      const id = this.options.id

      if(id){
        axios.get('admin/business-offers/' + id + '/get-file/deal_docs')
        .then((response) => {
          let dealDocsData = response.data
          /*dealDocsData = dealDocsData.sort(function (a, b) {
            return a.order - b.order
          })*/
          if (dealDocsData.length > 0) {
            that.upload.deal_docs = dealDocsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    getSellerNda () {
      const that = this
      const id = this.options.id
    
      if(id){
      axios.get('admin/business-offers/' + id + '/get-file/seller_nda')
      .then((response) => {
        let sellerNdaData = response.data
        /*sellerNdaData = sellerNdaData.sort(function (a, b) {
        return a.order - b.order
        })*/
        if (sellerNdaData.length > 0) {
        that.upload.seller_nda = sellerNdaData.map((el) => {
          return {
          id: el.id,
          uuid: el.uuid,
          name: el.name,
          type: 'server',
          url: el.original_url,
          order: el.order
          }
        })
        }
      })
      .catch((errors) => {
        console.dir(errors)
      })
      }
    },
    getAgentDocs () {
      const that = this
      const id = this.options.id
    
      if(id){
      axios.get('admin/business-offers/' + id + '/get-file/agent_docs')
      .then((response) => {
        let agentDocsData = response.data
        
        if (agentDocsData.length > 0) {
        that.upload.agent_docs = agentDocsData.map((el) => {
          return {
          id: el.id,
          uuid: el.uuid,
          name: el.name,
          type: 'server',
          url: el.original_url,
          order: el.order
          }
        })
        }
      })
      .catch((errors) => {
        console.dir(errors)
      })
      }
    },
    getSellerAgreementDocs () {
      const that = this
      const id = this.options.id

      if(id){
        axios.get('admin/business-offers/' + id + '/get-file/seller_agreement_docs')
        .then((response) => {
          let sellerAgreementDocsData = response.data
          /*dealDocsData = dealDocsData.sort(function (a, b) {
            return a.order - b.order
          })*/
          if (sellerAgreementDocsData.length > 0) {
            that.upload.seller_agreement_docs = sellerAgreementDocsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    getCurrencies(){
      axios.get('admin/data/currencies')
        .then(({ data }) => {
          this.allCurrencies = data
        })
        .catch(err => console.dir(err))
    },
    getUploadedFiles(el) {
      return this.upload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file) {
      if (this.upload[name].length > 1) {
        this.upload[name].splice(key, 1)
      } else {
        this.upload[name][0].name = null
      }

      if(file.id && this.options.id){
        axios.post('admin/business-offers/' + this.options.id +'/delete-file', {
        collection: name,
        media_id: file.id,
      })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name
        if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')
          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 5) {
          this.$noty.error('File is too large. Maximum - 5MB.')
          return false
        }

        this.upload[name] = this.upload[name].filter(e => e.name !== null)
        if (this.$refs[name].length < 10) {
          this.upload[name].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 10 files')
        }
      }
    },
    downloadFile(file, type) {
      const id = this.options.id
      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post('admin/business-offers/' + id +'/get-file-link', {
        collection: type,
        media_id: file.id,
      })
        .then(function (response) {
          if (response.data) {
            const url = response.data
            var fileURL = response.data;
            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async getCountries () {
      await axios.get('admin/data/countries')
        .then(({data}) => {
          this.countries = data
          this.updateForm()
        })
        .catch((error) => {
          console.log(error);
        })
    },
    async getFintechTypes(){
      await axios.get('admin/data/fintech-types')
        .then(({ data }) => {
          this.fintechTypes = data
        })
        .catch(err => console.dir(err))
    },
    async save () {
      this.form.manager_name = this.form.managers.map(obj => obj.name).join('|');
      
      if (this.isEdit) {
        await this.updateBusinessOffer({ id: this.options.id, form: this.form })
          .then(() => this.processFiles(this.options.id))
          .catch(error => console.log(error))
      } else {
        await this.createBusinessOffer(this.form)
          .then(res => {
            console.log(res);
            this.processFiles(res.data.data[0])
            for (const key in this.form) {
              if (Object.hasOwnProperty.call(this.form, key)) {
                this.form[key] = null
              }
            }
            
            this.close()
          })
          .catch(error => console.log(error))
      }
    },
    addLongDescription(){
      if(!this.form.long_description){
        this.form.long_description = this.default_long_description
      }
    },
    deleteLongDescription(){
      if(this.form.long_description){
        this.form.long_description = null
      }
    },
    processFiles(id){
      let data = new FormData()
      const uploadKeys = Object.keys(this.upload)

      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]
        for (let i = 0; i < this.upload[key].length; i++) {
          if (this.upload[key][i].name !== null) {
            let f = {}
            if (this.upload[key][i].type == 'server') {
              f = JSON.stringify(this.upload[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.upload[key][i].name)
              f = this.upload[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/business-offers/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
          console.log(response);
          this.close()
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;
      
        .inpt {
          width: 100%;
          color: #000000;
      
          &__ta {
            height: 7rem;
          }
        }
        .btn {
          padding: 0.5rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }
        .delete-btn{
          margin-left: auto;
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
  textarea{
    height: 5rem;
  }
  div.long-desc{
    width: 100%;
    .label{
      min-width: 15rem;
      max-width: 15rem;
      padding-right: 0.75rem;
      font-weight: bold;
    }
  }
  .btn.long-desc {
    padding: 0.5rem;
    background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
    border-radius: 6px;
    color: #fff;
    font-size: 13px;
    border: none;
  }
  .btn.long-desc.delete{
    background: #B03636;
  }
  .seller_wrap{
    position: relative;
    z-index: 9;
    padding: 12px 0;
    margin: 12px 0;
    width: 100%;
    &:before{
      content: "";
      position: absolute;
      left: -52px;
      right: -52px;
      top: 0;
      bottom: 0;
      background: aliceblue;
      z-index: -1;;
    }
  }
  .file-template{
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 24px;
    height: 24px;
  }
  .box.box_addFiles{
    position: relative;
  }
}
</style>
