<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Invoice</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Choose receiver type</div>
        <div class="data">
          <div class="row gap-12">
            <div class="col">
              <div class="radio-btn-wrap">
                <label for="receiver_type_text">
                  Enter a text
                </label>
                <input @change="changeReceiverType" type="radio" id="receiver_type_text" name="receiver_type" class="inpt" value="text" v-model="receiver_type">
              </div>
            </div>
            <div class="col">
              <div class="radio-btn-wrap">
                <label for="receiver_type_user">
                  Customer
                </label>
                <input @change="changeReceiverType" type="radio" id="receiver_type_user" name="receiver_type" class="inpt" value="user" v-model="receiver_type">
              </div>
            </div>
            <div class="col">
              <div class="radio-btn-wrap">
                <label for="receiver_type_lead">
                  Internal deal
                </label>
                <input @change="changeReceiverType" type="radio" id="receiver_type_lead" name="receiver_type" class="inpt" value="lead" v-model="receiver_type">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%" v-if="receiver_type == 'user' || receiver_type == 'lead'">
        <div class="row">
          <div class="label"></div>
          <div class="data">
            <input type="text" class="inpt" placeholder="Enter for search" v-model="search_receiver_query" @keyup="searchReceiver">
          </div>
        </div>
        <div class="row">
          <div class="label"> </div>
          <div class="data">
            <select @change="selectReceiver" class="inpt" style="width: 100%;" v-model="receiver">
              <option :value="null">Select receiver</option>
              <option v-for="item in receivers" :key="item.id" :value="item">{{ getReceiverName(item) }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Receiver</div>
        <div class="data">
          <input type="text" name="receiver" class="inpt" v-model="form.receiver" :class="{ 'input-error': errors.receiver }" placeholder="Enter receiver">
          <div class="error-message" v-if="errors.receiver">{{ errors.receiver[0] }}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">
          Invoice positions
        </div>
        <div class="data">
          <div v-for="(item, i) in form.invoice_items">
            <div>
              <div class="row">
                <div class="data">
                  <div class="row">
                    <div class="label">
                      Item № {{ i + 1 }}
                    </div>
                    <div class="data">
                      <div class="delete-btn" @click="deleteInvoiceItem(i)">
                        <Close class="img"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row" style="flex-wrap: wrap">
                <div class="col" style="width: 100%">
                  <input type="text" name="description" class="inpt" v-model="item.description" placeholder="Service name" :class="{ 'input-error': getItemError(i, 'description') }">
                </div>
                <div class="error-message col" v-if="getItemError(i, 'description')" style="width: 100%">
                  {{ getItemError(i, 'description') }}
                </div>
              </div>
              <div class="row" style="flex-wrap: wrap">
                <div class="col" style="width: 100%">
                  <input type="number" name="amount" @change="calculateInvoiceItem" class="inpt" v-model="item.amount" placeholder="Service price" :class="{ 'input-error': getItemError(i, 'amount') }">
                </div>
                <div class="error-message col" v-if="getItemError(i, 'amount')" style="width: 100%">
                  {{ getItemError(i, 'amount') }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addInvoiceItem">
                  Add invoice item
                </span>
              </div>
            </div>
          </div>
          <div class="error-message" v-if="errors.invoice_items">{{ errors.invoice_items[0] }}</div>
        </div>
      </div>
      <div class="row row__price">
        <div class="label">Amount</div>
        <div class="data" style="flex-wrap: wrap">
          <div style="width: 100%; display: flex">
            <input type="number" readonly class=inpt name="amount" v-model="form.amount" :class="{ 'input-error': errors.amount }" placeholder="Enter amount">
            <select v-if="currencies" v-model="form.currency_id" :class="{ 'input-error': errors.currency_id }">
              <option :value="null">Select currency</option>
              <option
                v-for="c in currencies" :key="c.id"
                :value="c.id"
                v-text="c.code"
              />
            </select>
          </div>
          <div style="width: 100%">
            <div v-if="errors.amount" class="error-message">{{ errors.amount[0] }}</div>
            <div v-if="errors.currency_id" class="error-message">{{ errors.currency_id[0] }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Payment method</div>
        <div class="data">
          <select name="status" class="inpt" v-model="form.payment_method" :class="{ 'input-error': errors.payment_method }">
            <option :value="null">Select payment method</option>
            <option
              v-for="method in payment_methods" :key="method"
              :selected="method === form.method"
              :value="method"
            >
              {{ method }}
            </option>
          </select>
          <div v-if="errors.payment_method" class="error-message">{{ errors.payment_method[0] }}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">Status</div>
        <div class="data">
          <select name="status" class="inpt" v-model="form.status" :class="{ 'input-error': errors.status }">
            <option :value="null">Select status</option>
            <option
              v-for="status in statuses" :key="status"
              :selected="status === form.status"
              :value="status"
            >
              {{ status }}
            </option>
          </select>
          <div v-if="errors.status" class="error-message">{{ errors.status[0] }}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">Deadline at</div>
        <div class="data">
          <input type="date" name="deadline_at" class="inpt" v-model="form.deadline_at"  placeholder="Enter deadline date">
        </div>
      </div>
      <div class="row row_files">
        <div class="box box_addFiles">
          <div v-if="getUploadedFiles('invoice').length" class="content content_many">
            <draggable v-model="upload.invoice" tag="div" class="draggable">
            <div v-for="(file, key) in getUploadedFiles('invoice')" :key="key" class="file">
              <BlueFile class="img" />
              <!--<div class="btn btn_del" @click="delFile('invoice', key, file)"><Close class="img" /></div>-->
              <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'invoice')"><BlueDownload class="img" /></div>
              <div class="desc">{{ file.name }}</div>
            </div>
            </draggable>
            <!--<div class="add">
              <div class="btn" @click="$refs.invoice[upload.invoice.length - 1].click()"><Close class="img" /></div>
            </div>-->
          </div>
          <!---<div v-else class="content">
            <div class="choose">
              <div class="btn" @click="$refs.invoice[0].click()">Invoice</div>
            </div>
          </div>-->
            <input
              v-for="(i, key) in upload.invoice"
              :key="key"
              type="file"
              :name="`invoice${key}`"
              class="hide"
              ref="invoice"
              @change="addInput($event, 'invoice', key)"
            >
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
    Close,
    Tmblr,
    BlueDownload,
    BlueFile,
    draggable,
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data(){
    return{
      currencies: [],
      payment_methods: [
        'SEPA',
        'SWIFT',
        'Crypto'
      ],
      statuses: [
        'Draft',
        'Pending',
        'Partially paid',
        'Paid',
        'Overdue',
        'Cancelled'
      ],
      form: {
        receiver: null,
        amount: null,
        status: null,
        payment_method: null,
        user_id: null,
        lead_id: null,
        deadline_at: null,
        currency_id: null,
        invoice_items: [],
      },
      receiver_type: 'text',
      search_receiver_query: '',
      receivers: [],
      receiver: null,
      errors: {},
      upload: {
        invoice: [{ name: null }],
      }
    }
  },
  computed: {
    isEdit () {
      return this.options && this.options.id && this.options.form
    }
  },
  async created () {
    if(this.isEdit){
      await this.getInvoice()
      await this.getInvoiceDoc()
    }

    await this.getCurrencies()
  },
  methods: {
    changeReceiverType(){
      this.resetReceivers();
      
      this.search_receiver_query = '';
    },
    resetReceivers(){
      this.form.receiver = '';
      this.receivers = [];
      this.receiver = null;
      this.form.invoice_items = [];
    },
    getReceiverName(item){
      if(this.receiver_type == 'user'){
        return this.getUserName(item);
      } else if(this.receiver_type == 'lead'){
        return this.getLeadName(item);
      }
    },
    getReceiverField(item){
      if(this.receiver_type == 'user'){
        return this.getUserName(item, false);
      } else if(this.receiver_type == 'lead'){
        return this.getLeadReceiverName(item);
      }
    },
    getLeadName(item){
      return 'Imternal Deal #' + item.id + ' ' + 'Client number: ' + item.client_number 
    },
    getLeadReceiverName(item){
      return item.client_name;
    },
    getUserName(item, includeEmail = true){
      let name = '';
      
      if(item.full_name){
        name = item.full_name;
      } else if(item.name){
        name = item.name;
      }
      
      if(item.company_full_name){
        name += ' ' + item.company_full_name;
      }
      
      if(includeEmail){
         name += ' ' + item.email;
      }
      
      return name;
    },
    async searchReceiver(){
      this.resetReceivers();
      
      if(this.search_receiver_query){
        if(this.receiver_type == 'user'){
          await this.searchUsers(this.search_receiver_query);
        } else if(this.receiver_type == 'lead'){
          await this.searchLeads(this.search_receiver_query);
        }
      } else{
        this.receivers = [];
      }
    },
    getItemError(index, field) {
      return this.errors[`invoice_items.${index}.${field}`]
        ? this.errors[`invoice_items.${index}.${field}`][0]
        : null;
    },
    selectReceiver(event){
      setTimeout(() => {
        
      }, 1);
      
      let receiver = this.receiver
      
      if(this.receiver_type == 'user'){
        this.form.user_id = receiver.id;
      } else if(this.receiver_type == 'lead'){
        this.form.lead_id = receiver.id;
        
        this.getInvoicePositionsFromLead(receiver);
      }
      
      this.form.receiver = this.getReceiverField(receiver);
    },
    getInvoicePositionsFromLead(lead){
      if(lead.lead_balance && lead.lead_balance.lead_balance_items && lead.lead_balance.lead_balance_items.length){
        lead.lead_balance.lead_balance_items.forEach((item, i) => {
          this.form.invoice_items.push({
            description: item.service,
            amount: item.public_price
          });
        });
        
        this.calculateInvoiceItem()
      }
    },
    searchUsers(search){
      const that = this
      
      const basePath = process.env.VUE_APP_BACKEND_API_URL + 'admin/customers/search'
      
      const data = {search: search}
      
      axios.post(basePath, data).then(function (response) {
        that.receivers = response.data.users
      }).catch((errors) => {
        console.dir(errors)
      })
    },
    searchLeads(search){
      const that = this
      
      const basePath = process.env.VUE_APP_BACKEND_API_URL + 'admin/leads/search'
      
      const data = {search: search}
      
      axios.post(basePath, data).then(function (response) {
        that.receivers = response.data.leads
      }).catch((errors) => {
        console.dir(errors)
      })
    },
    addInvoiceItem(){
      this.form.invoice_items.push({
        description: null,
        amount: null
      })
      
      this.calculateInvoiceItem()
    },
    deleteInvoiceItem(index){
      if(this.form.invoice_items[index].hasOwnProperty('id')){
        this.form.invoice_items_to_delete.push(this.form.invoice_items[index])
      }

      this.form.invoice_items.splice(index, 1)
      
      this.calculateInvoiceItem()
    },
    calculateInvoiceItem(){
      let amount = 0;
      
      this.form.invoice_items.forEach((item, i) => {
        amount += +item.amount
      });
      
      this.form.amount = amount
    },
    async getInvoiceDoc () {
      const that = this
      const id = this.options.id
      
      if(id){
        await axios.get('admin/invoices/' + id + '/get-file/invoice')
        .then((response) => {
          let docsData = response.data

          if(docsData.length > 0) {
            that.upload.invoice = docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    getUploadedFiles(el) {
      return this.upload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file) {
      if (this.upload[name].length > 1) {
        this.upload[name].splice(key, 1)
      } else {
        this.upload[name][0].name = null
      }

      if(file.id && this.options.id){
        axios.post('admin/invoices/' + this.options.id +'/delete-file', {
        collection: name,
        media_id: file.id,
      })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name
        if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')
          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 10) {
          this.$noty.error('File is too large. Maximum - 10MB.')
          return false
        }

        this.upload[name] = this.upload[name].filter(e => e.name !== null)
        if (this.$refs[name].length < 10) {
          this.upload[name].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 10 files')
        }
      }
    },
    downloadFile(file, type) {
      const id = this.options.id
      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post('admin/invoices/' + id +'/get-file-link', {
        collection: type,
        media_id: file.id,
      })
        .then(function (response) {
          if (response.data) {
            const url = response.data
            var fileURL = response.data;
            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async getInvoice(){
      const that = this
      await axios.get('admin/invoices/' + this.options.id)
      .then(function (response) {
        that.form = response.data
        that.form.invoice_items_to_delete = []
        
        if(that.form.user_id){
          that.receiver_type = 'user'
          
          that.search_receiver_query = that.form.user.email;
          that.receivers.push(that.form.user)
          that.receiver = that.form.user
        } else if(that.form.lead_id){
          that.receiver_type = 'lead'
        }
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    async getCurrencies () {
      await axios.get('admin/data/currencies')
      .then(({data}) => {
        this.currencies = data
      })
      .catch((error) => {
        console.log(error);
      })
    },
    async save(){
      this.errors = {};
      
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/invoices';
      
      if(this.isEdit){
        url += '/' + this.options.id 
      }
    
      try {
        const response = await axios.post(url, this.form);
        
        //this.processFiles(response.data.invoice.id)
        
        if(this.isEdit){
          this.$store.dispatch('editItemFromTable', {
            id: this.options.id,
            table: this.options.table,
            actionType: this.options.actionType,
          })
        }
        else{
          this.$store.dispatch('createItemInTable', {
            table: this.options.table,
            actionType: this.options.actionType,
          })
        }
        
        this.close();
      } catch (error) {
        if (error.response && error.response.data) {
          this.errors = error.response.data.errors || {};
          
          this.$noty.error("Validation error occurred. Please check the fields.");
        }
      }
    },
    processFiles(id){
      let data = new FormData()
      const uploadKeys = Object.keys(this.upload)

      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]
        for (let i = 0; i < this.upload[key].length; i++) {
          if (this.upload[key][i].name !== null) {
            let f = {}
            if (this.upload[key][i].type == 'server') {
              f = JSON.stringify(this.upload[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.upload[key][i].name)
              f = this.upload[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/invoices/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
        this.close()
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    changeState(property){
      this.form[property] = !this.form[property]
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
        max-width: 160px;
      }

      .data {
        flex-grow: 1;
        
        .radio-btn-wrap{
          display: flex;
          flex-direction: row;
          align-items: center;
          input{
            width: auto;
            order: -1;
            margin: 0;
            width: 16px;
            height: 16px;
            display: inline-block;
            margin-right: 4px;
          }
          label{
            flex-shrink: 1;
          }
        }

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
        .btn {
          padding: 0.5rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }
        .delete-btn{
          margin-left: auto;
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            padding: .25rem .5rem;
            border-radius: 6px;
            border: 1px solid #1763fb;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
  textarea{
    height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
.error-message{
  color: red;
}
.input-error{
  border-color: red;
}
</style>
