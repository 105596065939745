<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Internal contact</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Approve to List</div>
        <div class="data c-tmblr" :disabled="form.business_offer_id">
          <Tmblr :active="!!form.approve_to_list" :class="'c-tmblr'" :text="'Approve to List'" @changeState="changeState('approve_to_list')" />
        </div>
      </div>
      <div class="row">
        <div class="label">Is Top</div>
        <div class="data c-tmblr">
          <Tmblr :active="!!form.is_top" :class="'c-tmblr'" :text="'Is Top'" @changeState="changeState('is_top')" />
        </div>
      </div>
      <div class="row">
        <div class="label">Managers</div>
        <div class="data">
          <div v-for="(formManager, formManagerIndex) in form.managers">
            <div>
              <div class="row">
                <div class="data">
                  <div class="row">
                    <div class="label">
                      Manager № {{ formManagerIndex + 1 }}
                    </div>
                    <div class="data">
                      <div class="delete-btn" @click="deleteManager(formManagerIndex)">
                        <Close class="img"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <select name="manager_name" class="inpt" v-model="formManager.name">
                <option :value="null">Select manager</option>
                <option 
                  v-for="(manager, i) in managers" 
                  :value="manager.name"
                >
                  {{ manager.name }}
                </option>
              </select>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="data">
                <span class="btn" @click="addManager">
                  Add manager
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Status</div>
        <div class="data">
          <select name="priority" class="inpt" v-model="form.status">
            <option :value="null">Select status</option>
            <option
              v-for="status in statuses" :key="status"
              :selected="status === form.status"
              :value="status"
            >
              {{ status }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Country</div>
        <div class="data">
          <select name="country_id" class="inpt" v-model="form.country_id">
            <option :value="null">Select country</option>
            <option
              v-for="country in countries" :key="country.id"
              :selected="country.id === form.country_id"
              :value="country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Company name</div>
        <div class="data">
          <input type="text" name="company_name" class="inpt" v-model="form.company_name" placeholder="Company Name">
        </div>
      </div>
      <div class="row">
        <div class="label">Service Type</div>
        <div class="data">
          <input type="text" name="service_type" class="inpt" v-model="form.service_type" placeholder="Service Type">
        </div>
      </div>
      <div class="row">
        <div class="label">Provider Type</div>
        <div class="data">
          <input type="text" name="provider_type" class="inpt" v-model="form.provider_type" placeholder="Provider Type">
        </div>
      </div>
      <div class="row">
        <div class="label">Channel</div>
        <div class="data">
          <input type="text" name="channel" class="inpt" v-model="form.channel" placeholder="Channel/AI">
        </div>
      </div>
      <div class="row">
        <div class="label">Web site</div>
        <div class="data">
          <input type="text" name="web_site" class="inpt" v-model="form.web_site" placeholder="Web Site Link">
        </div>
      </div>
      <div class="row">
        <div class="label">Contact Data</div>
        <div class="data">
          <input type="text" name="contact_data" class="inpt" v-model="form.contact_data" placeholder="Contact Data">
        </div>
      </div>
      <div class="row">
        <div class="label">Email</div>
        <div class="data">
          <input type="text" name="email" class="inpt" v-model="form.email" placeholder="Email">
        </div>
      </div>
      <div class="row">
        <div class="label">LinkedIn</div>
        <div class="data">
          <input type="text" name="linkedin" class="inpt" v-model="form.linkedin" placeholder="LinkedIn">
        </div>
      </div>
      <div class="row">
        <div class="label">Price</div>
        <div class="data">
          <input type="text" name="price" class="inpt" v-model="form.price" placeholder="Price">
        </div>
      </div>
      <div class="row">
        <div class="label">Price Note</div>
        <div class="data">
          <input type="text" name="price_note" class="inpt" v-model="form.price_note" placeholder="Price Note">
        </div>
      </div>
      <div class="row">
        <div class="label">First Message Status</div>
        <div class="data">
          <input type="text" name="first_message_status" class="inpt" v-model="form.first_message_status" placeholder="First Message Status">
        </div>
      </div>
      <div class="row">
        <div class="label">First Call Date</div>
        <div class="data">
          <input type="date" name="first_call_date" class="inpt" v-model="form.first_call_date" placeholder="First Call Date">
        </div>
      </div>
      <div class="row">
        <div class="label">Call history</div>
        <div class="data">
          <textarea type="date" name="call_history" class="inpt" v-model="form.call_history" placeholder="Call history"></textarea>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import Close from '../img/Close.vue'
import Tmblr from '../elem/Tmblr.vue'
import BlueFile from '../img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
    Close,
    Tmblr,
    BlueDownload,
    BlueFile,
    draggable,
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data(){
    return{
      countries: [],
      managers: [],
      statuses: [
        'Active',
        'In Progress',
        'Push',
        'Lost',
        'Close win',
        'Not active',
        'Clarification needed',
        'Hold',
        'Closed'
      ],
      form: {
        managers: [],
        manager_name: null,
        status: null,
        country_id: null,
        company_name: null,
        service_type: null,
        provider_type: null,
        channel: null,
        web_site: null,
        contact_data: null,
        email: null,
        linkedin: null,
        price: null,
        first_message_status: null,
        first_call_date: null,
        call_history: null,
        approve_to_list: false,
      },
      error: null,
    }
  },
  computed: {
    isEdit () {
      return this.options && this.options.id && this.options.form
    }
  },
  async created () {
    if(this.isEdit){
      await this.getLeadContact()
      await this.updateFormData()
    }

    await this.getCountries()
    await this.getManagers()
  },
  methods: {
    addManager(){
      this.form.managers.push({
        name: null
      })
    },
    deleteManager(index){
      this.form.managers.splice(index, 1)
    },
    updateFormData(){
      let arrManagers = [];
      
      if(this.form.manager_name){
        arrManagers = this.form.manager_name.split('|').map(name => {
          return { name: name };
        });
      }
      
      if(this.form.business_offer_id){
        this.$set(this.form, 'approve_to_list',  true)
      } else{
        this.$set(this.form, 'approve_to_list',  false)
      }
      
      this.$set(this.form, 'managers',  arrManagers)
    },
    async getLeadContact(){
      const that = this
      await axios.get('admin/lead-contacts/' + this.options.id)
      .then(function (response) {
        that.form = response.data
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    async getCountries () {
      await axios.get('admin/data/countries')
      .then(({data}) => {
        this.countries = data
      })
      .catch((error) => {
        console.log(error);
      })
    },
    async getManagers () {
      const that = this
      await axios.get('admin/data/managers')
        .then(function (response) {
          that.managers = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
	  },
    async save(){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/lead-contacts'

      if (this.isEdit) {
        url += '/' + this.options.id
      }

      this.form.manager_name = this.form.managers.map(obj => obj.name).join('|');

      await axios.post(url, this.form)
        .then((response) => {
          this.close();

          if(this.isEdit){
            this.$store.dispatch('editItemFromTable', {
              id: this.options.id,
              table: this.options.table,
              actionType: this.options.actionType,
            })
          }
          else{
            this.$store.dispatch('createItemInTable', {
              table: this.options.table,
              actionType: this.options.actionType,
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    changeState(property){
      if(property == 'approve_to_list' && this.form.business_offer_id){
        return '';
      }
      
      this.form[property] = !this.form[property]
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
        max-width: 160px;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
        .btn {
          padding: 0.5rem;
          background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }
        .delete-btn{
          margin-left: auto;
          width: 24px;
          height: 24px;
          background-color: #B03636;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            fill: #fff;
            max-width: 16px;
            max-height: 16px;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #333333;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
  textarea{
    height: 5rem;
  }
}
.gap-12{
  gap: 12px;
}
</style>
