<template>
  <!--<overlay-scrollbars class="page page-personal">-->
	<div class="page page-personal">
		<div class="box-container">
	  		<div class="transparent-box box_b-info">
				<div class="content">
		  		<div class="row person-info">
					<div>
			  		<div @click="createInvoice" class="btn create-account-btn">Create Invoice</div>
					</div>
					<div class="card">
			  		<div class="data">
						<div class="ttl">Deadline broked</div>
						<div class="desc" :class="{'breaked-deadline': breakedDeadline > 0}">
				  		<span>
							Count: {{ breakedDeadline }}
				  		</span>
				  		<span v-if="breakedDeadline > 0">
							<Tmblr 
					  		:active="showBreakedDeadline" 
					  		:text="'Show'" 
					  		@changeState="filterInvoices" />
				  		</span>
						</div>
			  		</div>
					</div>
		  		</div>
		  		<div class="row person-info filter">
					<div class="col">
			  		<input 
						name="search_query" 
						v-model="search_query" 
						class="inpt" 
						placeholder="Enter to search" 
						@change="getInvoices"
						@keyup.enter="getInvoices" 
			  		/>
					</div>
					<div class="col">
			  		<select name="status" class="inpt" v-model="search_status" @change="getInvoices">
						<option :value="null">Select status</option>
						<option 
				  		v-for="(status, i) in statuses"
				  		:value="status"
						>
				  		{{ status }}
						</option>
			  		</select>
					</div>
					<div class="col">
			  		<select name="status" class="inpt" v-model="search_payment_method" @change="getInvoices">
						<option :value="null">Select payment method</option>
						<option 
				  		v-for="(method, i) in paymentMethods"
				  		:value="method"
						>
				  		{{ method }}
						</option>
			  		</select>
					</div>
					<div class="col">
			  		<div class="btn" @click="getInvoices">
						Search
			  		</div>
					</div>
		  		</div>
		
		  		<div class="block">
					<Table
						@editItem="editItem"
						@deleteItem="deleteItem"
						:key="invoicesTableKey"
						:options="invoicesTableData"
						@change-page="getInvoices"
					/>
		  		</div>
				</div>
	  		</div>
		</div>
	</div>
  <!--</overlay-scrollbars>-->
</template>
  
<script>
  import Pen from '../components/img/Pen.vue'
  import User from '../components/img/User.vue'
  import Table from '../components/elem/Table.vue'
  import Pagination from 'laravel-vue-pagination'
  import Tmblr from '../components/elem/Tmblr.vue'
  
  export default {
	components: {
	  Pen,
	  User,
	  Pagination,
	  Table,
	  Tmblr
	},
	data(){
		return {
			invoicesTableKey: 0,
			invoicesTableData: {
				objName: 'invoices',
				colsWidth: ['5%', '18%', '18%', '18%', '18%', '18%', '5%'],
				header: [
				  { ttl: '#', info: null },
				  { ttl: 'Receiver', info: null },
				  { ttl: 'Amount', info: null },
				  { ttl: 'Payment Method', info: null },
				  { ttl: 'Status', info: null},
				  { ttl: 'Deadline at', info: null },
				  { ttl: '', info: null },
				],
				body: [],
				paginationData: null,
				sort: {
				  name: '',
				  order: 'desc',
				  loading: false
				}
			},
			breakedDeadline: false,
			showBreakedDeadline: false,
			search_query: null,
			search_payment_method: null,
			search_status: null,
			paymentMethods: [
				'SEPA',
				'SWIFT',
				'Crypto'
			],
			statuses: [
				'Draft',
			    'Pending',
				'Partially paid',
				'Paid',
			    'Overdue',
				'Cancelled'
			]
		}
	},
	async created () {
	  await this.getInvoices()
	},
	methods: {
	  filterInvoices(){
		this.showBreakedDeadline = !this.showBreakedDeadline

		this.getInvoices()
	  },
	  getInvoices(page = 1){
		const that = this

		const basePath = process.env.VUE_APP_BACKEND_API_URL + 'admin/invoices?page=' + page + '&per_page=15'

		const sortParams = '&sort=' + this.invoicesTableData.sort.name + '&order=' + this.invoicesTableData.sort.order

		let filterParams = ''

		filterParams = this.showBreakedDeadline ? '&breaked_deadline=true' : ''

		filterParams += this.search_query ? '&search=' + this.search_query : ''

		filterParams += this.search_payment_method ? '&payment_method=' + this.search_payment_method : ''
		
		filterParams += this.search_status ? '&status=' + this.search_status : ''

		axios.get(basePath + sortParams + filterParams)
		  .then(function (response) {
			that.invoicesTableData.paginationData = response.data.data

			that.invoicesTableData.body = []

			that.breakedDeadline = response.data.breaked_deadline

			response.data.data.data.forEach(el => {
			  let amount = el.currency
				? `${that.formatAmount(el.amount)} ${el.currency.code}`
				: `${that.formatAmount(el.amount)} EUR`;
				
			  that.invoicesTableData.body.push([
				el.id,
				el.receiver,
				amount,
				el.payment_method,
				{type: 'invoice-status', value: el.status,},
				{type: 'deadline_lite', ...that.getDeadlinePeriod(el.deadline_at)},
				{ type: 'edit-delete-invoice-actions', id: el.id, invoice_url: el.invoice_url }, 
			  ])
			})

			if(that.invoicesTableData.sort.name) that.invoicesTableData.sort.loading = false
		  })
		  .catch((errors) => {
			console.dir(errors)
		  })
	  },
	  formatAmount(value) {
		if (!value) return '0';
		
		return new Intl.NumberFormat('fr-FR', {
		  minimumFractionDigits: 2,
		  maximumFractionDigits: 2,
		}).format(value);
	  },
	  getFormattedDate(value){
		const date = new Date(value);

		const formattedDate = date.toLocaleDateString('en-GB', {
		  day: '2-digit',
		  month: '2-digit',
		  year: 'numeric'
		});

		return formattedDate;
	  },
	  getDeadlinePeriod(date){
		if(date != null){
		  const now = new Date();
		  const deadlineDate = new Date(date);

		  const difference = deadlineDate - now;

		  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
		  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

		  return { days, hours };
		}
	  },
	  deleteItem(obj){
		this.$store.commit('setModal', {
		  template: 'delete',
		  options: {
			id: obj.id,
			table: 'invoices',
			type: 'invoice',
			url: 'admin/invoices/' + obj.id,
			successfullMessage: 'You successfully deleted invoice',
		  }
		})
	  },
	  editItem(obj){
		this.$store.commit('setModal', {
		  template: 'invoice',
		  options: {
			form: obj,
			id: obj.id,
			actionType: 'edit',
			table: 'invoices',
			objectType: 'invoice',
			successfullMessage: 'You successfully updated invoice',
		  }
		})
	  },
	  createInvoice(){
		this.$store.commit('setModal', {
		  template: 'invoice',
		  options: {
			actionType: 'create',
			table: 'invoices',
			objectType: 'invoice',
			successfullMessage: 'You successfully create invoice',
		  }
		})
	  },
	},
	watch: {
	  '$store.state.deleteItemFromTableData': function(data) {
		let page = this.invoicesTableData.paginationData.current_page

		this.getInvoices(page)
	  },
	  '$store.state.editItemFromTableData': function(data) {
		let page = this.invoicesTableData.paginationData.current_page

		this.getInvoices(page)
	  },
	  '$store.state.createItemInTable': function(data) {
		let page = this.invoicesTableData.paginationData.current_page

		this.getInvoices(page)
	  },
	  '$store.state.user': function(data){
		let page = this.invoicesTableData.paginationData.current_page

		this.getInvoices(page)
	  }
	}
  }
</script>
  
<style lang="scss" scoped>
  .page-personal {
	padding: 1.5rem 0;
	.box {
	  &-container {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	  }
  
	  &_rate {
		position: relative;
		height: 7rem;
		height: 11vh;
		margin-top: 6rem;
		margin-top: 7vh;
		margin-bottom: 4rem;
		margin-bottom: 6vh;
  
		.box-inside {
		  width: 95%;
		  height: 11rem;
		  height: calc(100% + 6vh);
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%);
		  background: linear-gradient(113.24deg, #1763fb 16.01%, #1763fb 106.71%);
		  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
		  border-radius: 14px;
		  display: flex;
  
		  .content {
			flex-grow: 1;
  
			.scale-box {
			  width: 100%;
			}
		  }
  
		  .img {
			height: 14rem;
			height: calc(100% + 6vh);
			position: relative;
			top: 100%;
			transform: translateY(-96%);
			margin: auto 3rem;
			margin: auto 3vw;
		  }
		}
	  }
  
	  &_my-profile {
		position: relative;
		width: 100%;
		padding-right: 4.25rem;
  
		.header {
		  display: flex;
  
		  .title {
			flex-grow: 1;
		  }
  
		  .tmblr {
			display: flex;
			align-items: center;
			padding: 0 .25rem;
  
			.txt {
			  color: rgba(255, 255, 255, 0.5);
			}
  
			.btn {
			  &-tmblr {
				position: relative;
				width: 1.5rem;
				height: .5rem;
				background-color: rgba(255, 255, 255, 0.12);
				border-radius: 2rem;
				margin: auto 1rem;
  
				&-l::before {
				  content: '';
				  position: absolute;
				  top: 50%;
				  left: 0;
				  transform: translate(-50%, -50%);
				  width: 1rem;
				  height: 1rem;
				  border-radius: 50%;
				  background-color: #ffffff;
				}
  
				&-r::before {
				  content: '';
				  position: absolute;
				  top: 50%;
				  right: 0;
				  transform: translate(50%, -50%);
				  width: 1rem;
				  height: 1rem;
				  border-radius: 50%;
				  background-color: #ffffff;
				}
			  }
			}
  
			&-hide {
			  .txt {
				color: #1763fb;
			  }
  
			  .btn-tmblr-r::before {
				background-color:  #1763fb;
			  }
			}
		  }
		}
  
		.content {
		  padding: 0 0 1rem 0;
		  padding: 0 0 1.5vh 0;
		  display: flex;
  
		  &.blur {
			filter: blur(7px);
		  }
  
		  .card {
			&-big {
			  width: 100%;
			  height: 11.5rem;
			  display: flex;
			  align-items: flex-end;
			  background-image: url('/img/two_line.png');
			  background-position: 0 0;
			  background-repeat: no-repeat;
			  background-size: contain;
			  padding: 1.25rem 1.75rem;
  
			  svg.img {
				width: 3.5rem;
				height: 4rem;
				margin-right: 1rem;
			  }
  
			  .data {
				flex-grow: 1;
  
				.ttl {
				  font-size: 12px;
				  color: rgba(255, 255, 255, 0.5);
				  padding-bottom: .25rem;
				}
  
				.desc {
				  font-size: 1.25rem;
				  font-weight: 600;
				}
			  }
			}
		  }
  
		  .col {
			&-1 {
			  width: 27rem;
			  width: 21vw;
			  padding-right: .5rem;
			}
  
			&-2 {
			  flex-grow: 1;
			  padding-left: .5rem;
			  padding-right: .5rem;
			  display: flex;
  
			  .subcol {
				padding: 0 .5rem;
  
				&-1 {
				  width: 30%;
				}
  
				&-2,
				&-3 {
				  width: 35%;
				}
			  }
			}
		  }
		}
	  }
  
	  &_b-info {
		flex-grow: 1;
		position: relative;
		// background-color: #272E35;
		margin-right: 2rem;
  
		.person-info {
		  display: flex;
		  flex-direction: row;
		  justify-content: space-between;
		  gap: 12px;
		  &.filter{
			margin-bottom: 12px;
			justify-content: flex-start !important;
		  }
		}
  
		.header {
		  padding-right: 4.25rem;
  
		  .title {
			&::before {
			  background-color: rgb(255 255 255 / 25%);
			}
		  }
		}
  
		.content {
		  .row {
			display: flex;
			justify-content: space-between;
			align-items: center;
  
			.card {
			  background: rgba(23, 99, 251, 0.1);
			  width: 30%;
  
			  &-min {
				width: 20%;
			  }
			}
		  }
		}
	  }
  
	  &_comp-logo {
		width: 23rem;
		position: relative;
		background-color: #272E35;
		overflow: hidden;
		padding-bottom: 1rem;
  
		.dots {
		  position: absolute;
  
		  &-top {
			top: -5px;
			right: 7px;
			width: 2rem;
			opacity: .5;
		  }
  
		  &-left {
			top: 6.5rem;
			left: 28px;
			width: 1.5rem;
			opacity: .5;
		  }
  
		  &-bottom {
			bottom: -12px;
			right: 1.75rem;
			width: 2.5rem;
			transform: rotate(90deg);
			opacity: 0.5;
		  }
		}
  
		.header {
		  .title {
			&::before {
			  background-color: rgb(255 255 255 / 25%);
			}
		  }
		}
  
		.logo {
		  flex-grow: 1;
		  width: 14rem;
		  height: 14rem;
		  margin: .25rem auto;
		  background-position: center;
		  background-repeat: no-repeat;
		  background-size: cover;
		  border-radius: 50%;
		  display: flex;
		  align-items: flex-end;
		  justify-content: space-around;
		  position: relative;
		  z-index: 1;
  
		  .btn {
			width: 3.25rem;
			height: 3.25rem;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
  
			&_update {
			  background-color: #1763fb;
  
			  svg {
				width: 1.75rem;
				height: 1.75rem;
			  }
			}
  
			&_remove {
			  background-color: #B03636;
  
			  svg {
				width: 1.25rem;
				height: 1.25rem;
			  }
			}
		  }
		}
	  }
	}
  }
  
  .scale {
	position: relative;
	width: 100%;
	height: 1rem;
	margin: 1rem 0 3.5rem 0;
	border-radius: 3rem;
	background-color: rgba(255, 255, 255, 0.12);
  
	.progress {
	  position: relative;
	  height: 100%;
	  border-radius: 3rem;
	  background-color: #ffffff;
	  background-image: url('/img/rate_el.png');
	  background-position: 0 0;
	  background-repeat: repeat-x;
	  background-size: auto 100%;
  
	  &::before {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(50%, -50%);
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 5rem;
		background-color: #E2F4EC;
		background-image: url('/img/smile.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 65%;
	  }
  
	  .note {
		position: absolute;
		bottom: -3.25rem;
		right: 0;
		transform: translateX(50%);
		background-color: #094F4D;
		color: #ffffff;
		font-size: 1.125rem;
		font-weight: 500;
		line-height: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 7px 1rem 6px;
		border-radius: 6px;
  
		&::before {
		  content: '';
		  position: absolute;
		  top: -4px;
		  left: 50%;
		  transform: translateX(-50%) rotate(45deg);
		  width: .75rem;
		  height: .75rem;
		  background-color: #094F4D;
		  border-radius: 2px;
		}
	  }
	}
  }
  .btn{
	border-radius: 0.5rem; 
	padding: 12px 24px; 
	border: 1px solid #1763fb;
	background: #1763fb;
	display: inline-block;
  }
  .btn {
	&-edit {
	  width: 2.75rem;
	  height: 2.75rem;
	  position: absolute;
	  top: .75rem;
	  right: .75rem;
	  background: #22252B;
	  border-radius: 6px;
	  padding: .75rem;
	  display: flex;
	  align-items: center;
	  justify-content: center;
  
	  svg.img {
		width: 1rem;
		height: 1rem;
	  }
	}
	&-save {
	  width: 3.75rem;
	  height: 2.75rem;
	  position: absolute;
	  top: .75rem;
	  right: .75rem;
	  background: #22252B;
	  border-radius: 6px;
	  padding: .75rem 1rem;
	  display: flex;
	  align-items: center;
	  justify-content: center;
  
	  svg.img {
		width: 1rem;
		height: 1rem;
	  }
	}
  }
  
  .form-control {
	width: 100%;
	height: 100%;
	color: #ffffff;
	background-color: #1C2126;
	padding: 0.5rem 0.5rem 0.5rem 0.5rem;
	margin: auto 0rem;
	border: 1px solid #2F363D;
	border-radius: .75rem;
	box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
	outline: none;
	-webkit-appearance: none;
  
	&::placeholder {
	  color: #B9B9B9;
	}
  }
  
  .invalid-feedback {
	  width: 100%;
	  margin-top: .25rem;
	  font-size: 80%;
	  color: #dc3545;
  }
  
  .transparent-box {
	width: 100%;
	border-radius: 14px;
	margin: 1rem 0;
  }
  
  .excel-btn-wrapper {
	position: relative;
	height: 67px;
  }
  
  .excel-btn {
	border-radius: 0.5rem; 
	padding: 10px; 
	margin-left: 30px; 
	border: 1px solid #1763fb;
	display: inline-block;
	position:absolute;
	right: 15px;
	top: 0;
  }
  
  .create-account-btn {
	border-radius: 1rem;
	padding: 10px;
	background-color: #1763fb;
  }
  .breaked-deadline{
	color: red;
	display: flex;
	justify-content: space-between;
  }
  </style>
  